import React, { useState, useEffect } from 'react';
import './App.scss';
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import logo from "./images/logo_qvax.svg"

const App = () => {

  const texts = {
    NL: {
      title: "Welkom op QVAX",
      description: "Sinds 14/11/22 wordt QVAX niet meer gebruikt en alle persoonlijke data zijn gewist.",
      description2: "U kunt zich daarom ook niet meer inschrijven op QVAX voor uw vaccinatie.",
      description3: "Als u toch nog gevaccineerd wilt worden of vragen heeft over vaccinatie, kunt u terecht bij het vaccinatiecentrum in uw buurt.",
      description4: " Meer informatie over vaccinatie vindt u op ",
      url_text: "www.laatjevaccineren.be",
      url: "https://www.laatjevaccineren.be/home"
    },
    FR: {
      title: "Bienvenue sur QVAX",
      description: "Depuis le 14/11/22, QVAX n’est plus opérationnel et toutes les données ont été supprimées.",
      description2: "Il ne vous est donc plus possible de vous connecter sur votre compte anciennement créé ou d’en créer un nouveau.",
      description3: "Pour prendre un rendez-vous pour la vaccination Covid-19, veuillez-vous rendre sur le site ",
      url_text: "www.jemevaccine.be",
      url: "https://www.jemevaccine.be/"
    },
    EN: {
      title: "Welcome at QVAX",
      description: "Since 14/11/22 QVAX is no longer in use and all personal data has been erased.",
      description2: "As such you can no longer subscribe nor login to QVAX for Covid-19 vaccination purposes.",
      description3: "Should you still want to get vaccinated or have any remaining questions on vaccination, you can contact any vaccination center in your vicinity.",
      description4: "Further information can be found on any of the following websites: "
    },
    DE: {
      title: "Willkommen bei QVAX",
      description: "Seit dem 14/11/22 ist QVAX nicht mehr in Betrieb und alle personenbezogenen Daten wurden gelöscht.",
      description2: "Daher können Sie sich nicht mehr bei QVAX registrieren oder anmelden, um eine Covid-19 Impfung zu erhalten.",
      description3: "Wenn Sie sich dennoch impfen lassen möchten oder Fragen zur Covid-19 Impfung haben, können Sie sich an die Corona-Hotline der Deutschsprachigen Gemeinschaft wenden (Tel: 0800-23 0 32).",
      description4: "Weitere Informationen finden Sie zudem auf dem Coronaportal der Deutschsprachigen Gemeinschaft: ",
      url_text: "Ostbelgien Coronaportal - Impfen",
      url: "https://ostbelgiencorona.be/desktopdefault.aspx/tabid-7015/"
    },
  }

  const [language, setLanguage] = useState("FR")
  const [text, setText] = useState(texts[language])
  const [dropdownOpen, setDropdownOpen] = useState(false)


  const detectLanguage = () => {
    const lang = window.navigator.language || window.navigator.userLanguage
    if (lang) {
      const lan = lang.split('-')[1]
      setLanguage(lan)
    }
  }

  useEffect(() => {
    return () => {
      detectLanguage()
    }
  }, [])

  const handleLanguage = (lang) => {
    setLanguage(lang)
    setText(texts[lang])
  }

  const url = () => {
    return texts[language].url_text
  }

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen)
  }

  return (
    <div className="App">
      <div className="navbar">
        <div className="left">
          <img src={logo} alt="qvax logo" />
        </div>

        <div className="lang_selector">
          <div className="select">
            <span className={dropdownOpen ? "active" : ""}>
              {language}
            </span>
            <div className="icon-container" onClick={() => toggleDropdown()}>
              {!dropdownOpen && <ExpandLessIcon />}
              {dropdownOpen && <ExpandMoreIcon />}
            </div>
          </div>
          <div
            className={dropdownOpen ? "dropdown active" : "dropdown"}
          >
            <div onClick={() => handleLanguage("NL")}>{"NL"}</div>
            <div onClick={() => handleLanguage("FR")}>{"FR"}</div>
            <div onClick={() => handleLanguage("DE")}>{"DE"}</div>
            <div onClick={() => handleLanguage("EN")}>{"EN"}</div>
          </div>
        </div>

      </div>

      <div className="text">
        <h1>{text.title}</h1>
        <div className="description">
          <p>{text.description}</p>
          <p>{text.description2}</p>
          {language !== "FR" && <p>{text.description3}</p>}
          {language !== "FR" && <p>{text.description4} <a href={text.url} target="_blank" rel="noreferrer">{url()}</a></p>}
          {language === "FR" && <p>{text.description3} <a href={text.url} target="_blank" rel="noreferrer">{url()}</a></p>}
          {language === "EN" && (
            <ul className="url-list">
              <li>- In Dutch : <a href={texts["NL"].url} target="_blank" rel="noreferrer">{texts["NL"].url_text}</a>,</li>
              <li>- In French : <a href={texts["FR"].url} target="_blank" rel="noreferrer">{texts["FR"].url_text}</a>,</li>
              <li>- In German : <a href={texts["DE"].url} target="_blank" rel="noreferrer">{texts["DE"].url_text}</a></li>
            </ul>
          )}
          {/* {(language !== "EN") && (
            <a href={text.url} target="_blank" rel="noreferrer">
              {url()}.
            </a>
          )} */}
        </div>
      </div>
    </div>
  );
}

export default App;